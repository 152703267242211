import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'

class HtmlMap extends Component {
  state = {
    lat: 51.09417,
    lng: 17.01126,
    zoom: 16,
  }
  static propTypes = {
    name: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string
  }

  render() {
    const position = [this.state.lat, this.state.lng]
    if (typeof window !== 'undefined') {
    return (
      <Map center={position} zoom={this.state.zoom} style={{width:'100%', height:'100%'}}>
        <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
                  <Popup>
                    {this.props.name}
                    <br/>
                    {this.props.street}
                    <br/>
                    {this.props.city}
                  </Popup>
                </Marker>
      </Map>

    )
  }
  return null
  }
}

export default HtmlMap;
