import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from "gatsby";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'

export class ContactData extends Component {
  constructor(props){
    super(props)
    library.add(fab, fas)
  }
  static propTypes = {
    icons: PropTypes.object,
    dataContent: PropTypes.object,
    lang: PropTypes.string,
    langNo: PropTypes.string
  };

    render() {

    const content = this.props.dataContent;
    const iconTab = this.props.icons;
    const dataTab = [
      content.edges[0].node.contactD[0].konto,
      content.edges[0].node.contactD[0].email,
      content.edges[0].node.contactD[0].adress,
      content.edges[0].node.contactD[0].telephone,
      content.edges[0].node.contactD[0].telephone2
    ]
    const lang = this.props.lang - 1
    return (

    <Container fluid>
      <Row noGutters>
        <Col className="infoKontakt">
          <ListGroup variant="flush" className="px-2 align-content-center">
              {
                dataTab.map((itemsData,i)=>(

                    <ListGroup.Item className="m-3"><strong>{iconTab[i]!==undefined?<FontAwesomeIcon icon={iconTab[i]} />:itemsData[lang]}</strong><br/>
                        {itemsData[this.props.langNo]!==''?<a href={itemsData[this.props.langNo]}><div dangerouslySetInnerHTML={{ __html: itemsData[this.props.langNo+this.props.lang]}}/></a>:<div dangerouslySetInnerHTML={{ __html: itemsData[this.props.langNo+this.props.lang] }}></div>}
                    </ListGroup.Item>

              ))
              }

          </ListGroup>
        </Col>
      </Row>
    </Container>
    );
  }
 }

 export default props =>(
   <StaticQuery
     query={graphql`
       query {
         allJson(sort: {fields: contactD}, limit: 1) {
           edges {
             node {
               contactD {
                 adress
                 email
                 telephone
                 telephone2
                 konto
               }
             }
           }
         }
       }
     `}
     render = {({ allJson }) => <ContactData dataContent={allJson} {...props}/>}
       />
 );
