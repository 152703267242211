import React from 'react'
import PropTypes from "prop-types"
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

const FormG = ({controlId,name,typeC,nameC,valueC,onChangeC,isTouchedC,isErrorC,isOk,colNo})=>(
  <Form.Group as={Col} xs={colNo} controlId={controlId}>
    <Form.Label>{name}</Form.Label>
    <Form.Control
      type={typeC}
      name={nameC}
      value={valueC}
      onChange={onChangeC}
      isValid={isTouchedC && !isErrorC}
      isInvalid={isTouchedC && isErrorC}
    />
    <Form.Control.Feedback type="invalid">{isErrorC}</Form.Control.Feedback>
    <Form.Control.Feedback>{isOk}</Form.Control.Feedback>
  </Form.Group>
)

FormG.propTypes = {
  controlId: PropTypes.string,
  name: PropTypes.string,
  typeC: PropTypes.string,
  nameC: PropTypes.string,
  valueC: PropTypes.object,
  onChangeC: PropTypes.object,
  isTouchedC: PropTypes.object,
  isErrorC: PropTypes.object,
  isOk: PropTypes.string,
  colNo: PropTypes.string
}

export default FormG
